import React, { useState } from 'react';
import emailjs from '@emailjs/browser';
import ReCAPTCHA from 'react-google-recaptcha';

const Result = () => {
    return (    
        <p>
            Thank you for sending the message. We'll get back to you as soon as possible.
        </p>
    );
 
 };
 
 
function Contact(props) {


    const [result,showResult] = useState(false); 
    const [recaptchaValue, setRecaptchaValue] = useState(null);
    const onChange = (value) => {
        setRecaptchaValue(value);
    }

    const sendEmail = (e) => {
      e.preventDefault();

      if(recaptchaValue) {
        
        emailjs.sendForm('service_1gx1nz2', 'template_477j7bd', e.target, 'QJVjiMOP8Ww9eYyH5', 'g-recaptcha-response')
        .then(
            (result) => {
            console.log(result.text);
            }, (error) => {
            console.log(error.text);
            }
        );
        e.target.reset();
        showResult(true);
      } else {
        alert("Please verify the reCAPTCHA");
      }
    };

    const submitButtonStyle = {
        opacity: recaptchaValue ? 1 : 0.7,
        cursor: recaptchaValue ? 'pointer' : 'not-allowed'
      };
   
        return (
            <div className="site-main">
                <section className="ttm-row zero-padding-section clearfix"   >
                    <div className="container-xl">
                        <div className="row no-gutters">{/* row */}
                            <div className="col-lg-8">
                                <div className="spacing-7 ttm-bgcolor-white features-list">
                                    {/* section title */}
                                    <div className='row'>
                                        <div className='col'>
                                            <div className="section-title with-desc clearfix">
                                                <div className="title-header">
                                                    {/* <h5>Why Salesforce Audit from us</h5> */}
                                                    <h4 className="">The Free Salesforce Audit will include</h4>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {/* row 1 -data model and apex code*/}
                                    <div className="row">
                                        <div className="col-lg-6">
                                            {/* featured-icon-box */}
                                            <div className="featured-icon-box style2 left-icon icon-align-top">
                                                <div className="featured-icon">{/* featured-icon */}
                                                    <img src="images/fa-data-model-icon.png" />
                                                </div>
                                                <div className="featured-content">
                                                    <div className="featured-title">{/* featured title */}
                                                        <h5>Data Model</h5>
                                                    </div>
                                                    <div className="featured-desc">{/* featured desc */}
                                                        <p>To identify the potential inefficiencies in your data model and recommending the optimized approach where applicable.</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-6">
                                            {/* separator for small screens */}
                                            <div className="sep-line mt-4 mb-4 d-lg-none"/>
                                            {/* featured-icon-box */}
                                            <div className="featured-icon-box style2 left-icon icon-align-top">
                                                <div className="featured-icon">{/* featured-icon */}
                                                    <img src="images/fa-code-icon.png" />
                                                </div>
                                                <div className="featured-content">
                                                    <div className="featured-title">{/* featured title */}
                                                        <h5>Apex Code Practices</h5>
                                                    </div>
                                                    <div className="featured-desc">{/* featured desc */}
                                                        <p>To examine Apex code to assess adherence to the best practices, code quality and suggesting improvements.</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {/* row 1 ends*/}
                                    {/* separator */}
                                    <div className="row separator">
                                        <div className="col sep-line mt-4 mb-4" />
                                    </div>
                                    {/* separator */}
                                    {/* row 2 -integration and apex code*/}
                                    <div className="row">
                                        <div className="col-lg-6">
                                            {/* featured-icon-box */}
                                            <div className="featured-icon-box style2 left-icon icon-align-top">
                                                <div className="featured-icon">{/* featured-icon */}
                                                    <img src="images/fa-integration-icon.png" />
                                                </div>
                                                <div className="featured-content">
                                                    <div className="featured-title">{/* featured title */}
                                                        <h5>Integrations</h5>
                                                    </div>
                                                    <div className="featured-desc">{/* featured desc */}
                                                        <p>To review the potential integration issues, point of failures and advising on fail-safe approaches.</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-6">
                                            {/* separator for small screens */}
                                            <div className="sep-line mt-4 mb-4 d-lg-none"/>
                                            {/* featured-icon-box */}
                                            <div className="featured-icon-box style2 left-icon icon-align-top">
                                                <div className="featured-icon">{/* featured-icon */}
                                                    <img src="images/fa-automation-icon.png" />
                                                </div>
                                                <div className="featured-content">
                                                    <div className="featured-title">{/* featured title */}
                                                        <h5>Automations</h5>
                                                    </div>
                                                    <div className="featured-desc">{/* featured desc */}
                                                        <p>To Analyze your business process automations; workflows, Process Builders, Flows, and Triggers.</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {/* row 2 ends*/}
                                    {/* separator */}
                                    <div className="row separator">
                                        <div className="col sep-line mt-4 mb-4" />
                                    </div>
                                    {/* separator */}
                                    {/* row 3 -governance and performance*/}
                                    <div className="row">
                                        <div className="col-lg-6">
                                            {/* featured-icon-box */}
                                            <div className="featured-icon-box style2 left-icon icon-align-top">
                                                <div className="featured-icon">{/* featured-icon */}
                                                    <img src="images/fa-governance-icon.png" />
                                                </div>
                                                <div className="featured-content">
                                                    <div className="featured-title">{/* featured title */}
                                                        <h5>Overall Governance</h5>
                                                    </div>
                                                    <div className="featured-desc">{/* featured desc */}
                                                        <p>To audit and recommend about the overall governance of the project, agile management, source control, deployments and releases etc.</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-6">
                                            {/* separator for small screens */}
                                            <div className="sep-line mt-4 mb-4 d-lg-none"/>
                                            {/* featured-icon-box */}
                                            <div className="featured-icon-box style2 left-icon icon-align-top">
                                                <div className="featured-icon">{/* featured-icon */}
                                                    <img src="images/fa-performance-icon.png" />
                                                </div>
                                                <div className="featured-content">
                                                    <div className="featured-title">{/* featured title */}
                                                        <h5>Performance</h5>
                                                    </div>
                                                    <div className="featured-desc">{/* featured desc */}
                                                        <p>To analyze your instance's overall performance, determining where it tends to hit governor limits and suggesting about the areas for optimization.</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {/* row 3 ends*/}
                                </div>{/* features-list ends div ends*/}
                            </div>{/* col-lg-8 */}
                            {/* audit request form */}
                            <div className="col-lg-4">
                                <div className="spacing-7 ttm-bgcolor-grey ttm-bg ttm-col-bgcolor-yes text-center">
                                    <div className="ttm-col-wrapper-bg-layer ttm-bg-layer">
                                        <div className="ttm-bg-layer-inner" />
                                    </div>
                                    {/* section title */}
                                    <div className="section-title with-desc clearfix pt-sm-20">
                                        <div className="title-header">
                                            <h4>Send a message</h4>
                                            {/* <h2 className="title">Salesforce Audit Report</h2> */}
                                        </div>
                                    </div>{/* section title end */}
                                        <form onSubmit={sendEmail} id="ttm-quote-form" className="row audit-form ttm-quote-form clearfix" method="post" action="#">
                                            <div className="col-sm-12 col-md-12">
                                                <div className="form-group">
                                                <input name="full_name" type="text" className="form-control bg-white" placeholder="Full Name*" required="required" />
                                                </div>
                                            </div>
                                            <div className="col-sm-12 col-md-12">
                                                <div className="form-group">
                                                <input name="email" type="text" placeholder="Email Address" required="required" className="form-control bg-white" />
                                                </div>
                                            </div>
                                            <div className="col-sm-12 col-md-12">
                                                <div className="form-group">
                                                <input name="phone" type="text" placeholder="Phone" required="required" className="form-control bg-white" />
                                                </div>
                                            </div>
                                            <div className="col-sm-12 col-md-12">
                                                <div className="form-group">
                                                <textarea name="message" rows={5} placeholder="Write a massage..." required="required" className="form-control bg-white" defaultValue={""} />
                                                </div>
                                            </div>
                                            <div className="col-md-12">
                                                <ReCAPTCHA
                                                    sitekey="6LdslJseAAAAACd3ZGUyU2uuAlxaPb4GEjic9PwW"
                                                    onChange={onChange}
                                                />
                                                <div className="text-center">
                                                <button type="submit" id="submit" className="mt-3 ttm-btn ttm-btn-size-md ttm-bgcolor-skincolor" style={submitButtonStyle} disabled={recaptchaValue === null}>
                                                    Submit
                                                </button>
                                                <br/>

                                                <div classroom="row">
                                                    {result ? <Result/> : null}
                                                </div>
              
                                                </div>
                                            </div>
                                        </form>
                                </div>
                            </div>
                        </div>
                        {/* audit fom ends */}
                    </div> {/* row ends */}
                </section>
             </div>
            )
    
}


export default Contact;