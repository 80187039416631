import React, {Component} from "react";
import Slider from 'react-slick';


export class Founder_detail extends Component {
    render() {
        return (
<section className="element-row element-style clearfix">
                    <div className="container-xl">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="section-title margin-head-top text-center clearfix">
                                <h2 className=" title">About <span> The Founder </span></h2>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="ttm-row ttm-bgcolor-darkgrey ttm-bg clearfix">
                        <div className="container-xl">
                            <div className="row">
                                <div className="col-md-8 offset-md-2">
                                    <Slider className="slick_slider" arrows={false}>
                                        
                                        <div className="testimonials text-center"> 
                                            <div className="testimonial-content">{/* testimonials-content */}
                                            <div className="testimonial-avatar">
                                                <div className="testimonial-img">{/* testimonials-img */}
                                                <img className="img-center" alt="testimonial-img" src="./images/pic1.png" />
                                                </div>
                                            </div>
                                            <blockquote className="testimonial text-center"><p className="font-size">"The Salesforce Platform is great, but the Cost-effectiveness with Quality consulting services can enhance the ROI."</p><br/>
                                            <p className="Font-Style-Reg">Says Mustafa Parekh, the founder of HTTPeak Pty Ltd. He is coming from a strong Techno-Functional background, with several years of Cross-Cloud Salesforce Implementation experience for wide range of industries, including Education, Insurance, Government sector, Airlines, Pharmaceutical and more. Besides Salesforce, he has also worked on Custom and Bespoke solutions. This makes the company distinct from others right from the founding leadership.
He formed this team of experts at HTTPeak, with an aim to bring all this experience to customers at affordable rates, with No compromise on quality.</p></blockquote>
                                            {/* <div className="ttm-ratting-star">
                                                <i className="fa fa-star" />
                                                <i className="fa fa-star" />
                                                <i className="fa fa-star" />
                                                <i className="fa fa-star" />
                                                <i className="fa fa-star" />
                                            </div> */}
                                            <div className="testimonial-caption">{/* testimonials-caption */}
                                                <h6>Mustafa Parekh</h6>
                                                <label></label>
                                            </div>
                                            </div>
                                        </div>
                                        
                                    </Slider>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>


)
}
}


export default Founder_detail;