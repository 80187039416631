import React, { Component } from 'react';
import Header1 from '../layout/Header1';

import ContactComponents from './ContactComponents';
import Contact from './Contact';
import CarouselPage1 from './Home1_banner';

export class Salesforce_Audit extends Component {
    render() {
        return (
            <div className="site-main">
              <Header1 />
              {/* home banner */}
                       
              <CarouselPage1 />{/* home banner end*/}
              <div>
              <Contact/>
              </div>
      
              {/* Why Salesforce Section */}
              <div >
                <div className="spacing-7 section-title with-desc clearfix">
                  <div className="title-header text-center">
                    <h2 className="title">Are you interested in Free <span>Salesforce Org audit?</span></h2>
                    <h5 className="mt-2 text-transform-none">It will help you save your time and money in the future.</h5>
                  </div>

                  {/* first section */}
                  <div className=" clearfix">
                    <div className="container-xl">
                      <div className="row align-items-center">
                        <div className="col-md-7">
                          {/* section title */}
                          <div className="section-title with-desc clearfix">
                            <h2 className="title mb-4">Stay informed about <span>problems &amp; root cause:</span></h2>
                            <div className="margin-body-top title-desc">
                              <p>
                                Are you curious about the shortcomings (or weak areas) of your Salesforce implementation? Are you sick of issues and eager to know their root causes so they can be avoided? Salesforce free audit helps you highlight areas of improvement to enjoy the platform's real strength.
                                <br/><br/>
                                We can review your implementation, in order to identify potential issuess, customizations, security, integration with external systems, business process automation, overall governance and system performance. 
                                <br/><br/>
                                This can certainly help you take corrective measures to boost your business outcomes.
                              </p>
                            </div>
                          </div>
                          {/* section title end */}
                        </div>
                        <div className="col-md-5 text-center">
                          <img className=" img-fluid" src="images/stay-informed.jpg" title="single-img-one" alt="single-img-one" />
                        </div>
                      </div>
                      {/* first Section ends */}
                      {/* separator */}
                      <div className="row separator">
                        <div className="col sep-line mt-4 mb-4" />
                      </div>
                      {/* separator */}
                      {/* Second Section  */}
                      <div className="row align-items-center">
                        <div className="col-md-5 text-center">
                          <img className=" img-fluid" src="images/save-future-costs.jpg" title="single-img-one" alt="single-img-one" />
                        </div>
                        <div className="col-md-7">
                          {/* section title */}
                          <div className="section-title with-desc clearfix">
                            <h2 className="title my-4">Save future <span>costs<span/></span></h2>
                            <div className="margin-body-top title-desc">
                              <p>
                                It is true that every business wants to spend within their planned budget and works hard to avoid unseen future costs. 
                                <br/><br/>
                                This free audit can help you avoid unanticipated future costs and take more informed decisions. The overall outcome of this activity is to increase the efficiency and better ROI. 
                                <br/><br/>
                                This audit will cost you $0 and will save you future costs.
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                      {/* second Section ends */}
                      {/* separator */}
                      <div className="row separator">
                        <div className="col sep-line mt-4 mb-4" />
                      </div>
                      {/* separator */}
                      {/* 3rd Section  */}
                      <div className="row align-items-center">
                        <div className="col-md-7">
                          {/* section title */}
                          <div className="section-title with-desc clearfix">
                            <h2 className="title my-4">Minimize Tech <span>Debt</span></h2>
                            <div className="margin-body-top title-desc">
                              <p>
                                The earlier an issue is identified, the less it will cost. We will review your Salesforce Org's customizations to ensure that they are aligned with best practices and avoid unnecessary technical debt. 
                                <br/><br/>
                                This can be a highly valuable outcome to have less of a tech-debt and will help you focus more on the actual organisational goals.
                                </p>
                            </div>

                          </div>
                          {/* section title end */}
                        </div>
                        <div className="col-md-5 text-center">
                          <img className=" img-fluid" src="images/minimize-tech-debt.jpg" title="single-img-one" alt="single-img-one" />
                        </div>
                      </div>
                      {/* 3rd section end */}
                      {/* separator */}
                      <div className="row separator">
                        <div className="col sep-line mt-4 mb-4" />
                      </div>
                      {/* separator */}
                      {/* 5th Section  */}
                      <div className="row align-items-center">
                        <div className="col-md-5 text-center">
                          <img className=" img-fluid" src="images/stay-compliant.jpg" title="single-img-one" alt="single-img-one" />
                        </div>
                        <div className="col-md-7">
                          {/* section title */}
                          <div className="section-title with-desc clearfix">
                            <h2 className="title my-4">Stay <span>Compliant</span></h2>
                            <div className="margin-body-top title-desc">
                              <p>
                                Compliance is money-saving. Another main objective of this free audit is to analyse if your Salesforce implementation is complaint to Salesforce Release Updates and current industry standards.
                                <br/><br/>
                                Additionally, we will advise you on most cost-effective solution options (both Salesforce and non-Salesforce). 
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                      {/* 5th Section ends */}
                      {/* separator */}
                      <div className="row separator">
                        <div className="col sep-line mt-4 mb-4" />
                      </div>
                      {/* separator */}
                      {/* 4th Section  */}
                      <div className="row align-items-center">
                        <div className="col-md-7">
                          {/* section title */}
                          <div className="section-title with-desc clearfix">
                            <h2 className="title my-4">Optimize the <span>Performance:</span></h2>
                            <div className="margin-body-top title-desc">
                              <p>
                                An optimized implementation always helps with running business operations smoothly. In our review of your implementation, we will provide a number of recommendations to fine-tune the performance in a variety of ways. 
                                <br/><br/>
                                These will include business process automation, data management, deployments, rollouts, increased productivity, and faster performance.
                              </p>
                            </div>

                          </div>
                          {/* section title end */}
                        </div>
                        <div className="col-md-5 text-center">
                          <img className=" img-fluid" src="images/optimize-salesforce-instance.jpg" title="single-img-one" alt="single-img-one" />
                        </div>
                      </div>
                      {/* 4th section end */}
                    </div>
                  </div>
                {/* section end */}
              </div>
            </div>
            {/* Why Salesforce Section End */}
            {/* Our Clients Section */}
            <div >
              <div className="section-title with-desc clearfix">
                <div className="title-header text-center">
                  
                  <h2 className="title">Some of our<span> clients</span></h2>
                </div>
                <div className="title-desc">
                  <img className='company-logos' src="./images/img20.jpg" />
                </div>
              </div>
            </div>

            {/* Our Clients Section End */}

            {/* Testimonial start */}
            <section className="ttm-row only-testimonial-section bg-img11 pb-0 res-1991-pb-0 clearfix">
              <div className="container-xl">
                <div className="row">{/* row */}
                  <div className="col-lg-12">
                    {/* section title */}
                    <div className="section-title with-desc text-center clearfix">
                      <div className="title-header margin-head-top">

                        <h2 className="title">What our clients say <span>about us</span></h2>
                      </div>
                    </div>{/* section title end */}
                  </div>
                </div>

                
                  <div className="wrapper-grid">


                    <div className="testimonials text-center">
                      <div className="testimonial-content">{/* testimonials-content */}
                        <div className="testimonial-avatar">
                          <div className="testimonial-img1">{/* testimonials-img */}
                            <img className="img-size img-center" alt="testimonial-img" src="images/userlogo.png" />
                          </div>
                        </div>
                        <blockquote><p className="quotation-mark">“</p><br/>The httpeak team supports us with consulting and integration around the Salesforce Marketing Cloud. They understand the requirements very quickly and implement everything as agreed. It is a pleasure to work with their team and we can highly recommend working with them.​</blockquote>
                        <div className="ttm-ratting-star">
                          <i className="fa fa-star" />
                          <i className="fa fa-star" />
                          <i className="fa fa-star" />
                          <i className="fa fa-star" />
                          <i className="fa fa-star" />
                        </div>
                        <div className="testimonial-caption">{/* testimonials-caption */}
                          <h6>Gregor Nilsson</h6>
                          <label>Partner, Managing Director Switzerland</label>
                        </div>
                      </div>
                    </div>


                    <div className="testimonials text-center">
                      <div className="testimonial-content">{/* testimonials-content */}
                        <div className="testimonial-avatar">
                          <div className="testimonial-img1">{/* testimonials-img */}
                            <img className="img-size img-center" alt="testimonial-img" src="images/userlogo2.png" />
                          </div>
                        </div>
                        <blockquote><p className="quotation-mark">“</p><br/> We love the approach HTTPeak take to support our company.​ They provide flexibility, personalised service with a structure approach to our needs.

                          It’s not just about solving the immediate problem but looking at it from a bigger perspective with the idea of how it will help 2-3 steps later. We are looking forward to growing our business with the technical support of HTTPeak solutions.​</blockquote>
                        <div className="ttm-ratting-star">
                          <i className="fa fa-star" />
                          <i className="fa fa-star" />
                          <i className="fa fa-star" />
                          <i className="fa fa-star" />
                          <i className="fa fa-star" />
                        </div>
                        <div className="testimonial-caption">{/* testimonials-caption */}
                          <h6>Onray Fernando</h6>
                          <label>National Eye Care</label>
                        </div>
                      </div>
                    </div>
                    </div>  


                  <div>
                    <div className="testimonials text-center">
                      <div className="testimonial-content">{/* testimonials-content */}
                        <div className="testimonial-avatar">
                          <div className="testimonial-img1">{/* testimonials-img */}
                            <img className="img-size img-center" alt="testimonial-img" src="images/userlogo3.png" />
                          </div>
                        </div>
                        <blockquote><p className="quotation-mark">“</p><br/> While our latest product offering is in its infancy, our challenge statement to the team at HTTPeak was quite complex in both business and functional requirements.
                          The technical and delivery solution provided to solve our current and future Salesforce architecture requirements is of top quality.<br />

                          The technical and delivery solution provided to solve our current and future Salesforce architecture requirements is of top quality. I have particularly been impressed with the speed and autonomy of the change requirements beyond the introductory analysis meetings. I look forward to continuing our successful relationship together as our business grows.​</blockquote>
                        <div className="ttm-ratting-star">
                          <i className="fa fa-star" />
                          <i className="fa fa-star" />
                          <i className="fa fa-star" />
                          <i className="fa fa-star" />
                          <i className="fa fa-star" />
                        </div>
                        <div className="testimonial-caption">{/* testimonials-caption */}
                          <h6>Nadir</h6>
                          <label>CEO at PSLoop</label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                
              
            </section>

            {/* Testimonial end */}
            {/* aboutus-section */}
            <section id="whoweare" className="ttm-row aboutus-section clearfix">
              <div className="container-xl">
                <div className="row">
                  <div className="col-lg-6">
                    {/* section title */}
                    <div className="section-title with-desc clearfix">
                      <div className="title-header margin-head-top">
                        
                        <h2 className="title"><span>Who</span> we are?</h2>
                      </div>
                      <div className="margin-body-top title-desc">
                        <p>HTTPeak Pty Ltd. offers Salesforce and bespoke consulting services at a competitive price. Our journey started in 2016 from Melbourne, Australia.</p>
                        <p>Instead of just hard-selling everything, we listen to your requirements and budget constraints carefully and come up with the most appropriate solution that fits the bill.</p>
                        <p>Our leadership is highly technical, and our experts have specific industry experience with Salesforce implementations. Besides considering all the aspects such as Cost, Scalability, Business needs, solid architecture; we also perform peer-review on our work to maintain the quality.</p>
                        <p>That's  the reason why we have loyal customers that prefer to stay with us in a long term relationship.</p>
                      </div>

                    </div>
                    {/* section title end */}

                    <div className="row no-gutters mt-4">
                    </div>{/* row END*/}
                    {/* separator */}
                    <div className="separator">
                      <div className="sep-line mt-4 mb-4" />
                    </div>
                    {/* separator */}

                  </div>
                  <div className="col-lg-5 res-991-mt-40 res-991-text-center">
                    <div className="size-img position-relative res-991-mt-30">
                      {/* ttm_single_image-wrapper */}
                      <div className="">
                        <img className=" img-fluid" src="images/who-we-are.jpg" title="single-img-one" alt="single-img-one" />
                      </div>{/* ttm_single_image-wrapper end */}
                      {/*featured-icon-box
                          <div className="about-overlay-01">
                            <p className="mb-0">HTTPeak Pty Ltd.</p>
                          </div>
                          {/* featured-icon-box end*/}
                    </div>
                  </div>
                </div>
              </div>
            </section>
            {/* aboutus-section end */}
            {/* Contact Form */}
            <div className="contactme" id="contact">
              <div className="contactOverlay">
                <div className="contai ner">
                  <div className="form">
                    <ContactComponents />
                  </div>
                </div>
              </div>
            </div>

          </div>
            )
    }
}


export default Salesforce_Audit;