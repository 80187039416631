import React, {useState} from 'react'
import emailjs from '@emailjs/browser';
import ReCAPTCHA from 'react-google-recaptcha';

const Result = () => {
   return (
   
    <p>Thank you for contacting us. We'll get back to you as soon as possible.</p> 

   );

};


function ContactComponents(props) {


  const [result,showResult] = useState(false); 
  const [recaptchaValue, setRecaptchaValue] = useState(null);
  const onChange = (value) => {
      setRecaptchaValue(value);
  }


  const sendEmail = (e) => {
    e.preventDefault();

    if(recaptchaValue) {
      emailjs.sendForm('service_1gx1nz2', 'template_ogpckj5', e.target, 'QJVjiMOP8Ww9eYyH5', 'g-recaptcha-response')
        .then(
          (result) => {
            console.log(result.text);
        }, (error) => {
            console.log(error.text);
          }
        
        );
        e.target.reset();
        showResult(true);
      } else {
        alert("Please verify the reCAPTCHA");
      }
  };

  const submitButtonStyle = {
    opacity: recaptchaValue ? 1 : 0.7,
    cursor: recaptchaValue ? 'pointer' : 'not-allowed'
  };

  return (
    
    <form action="" onSubmit={sendEmail}>
                     

              <div className="formWord">
              
            
                     <div className="section-title  clearfix">
                            <div className="title-header">
                                <h2 className=" title  "> <span>Contact</span>&nbsp;HTTPeak</h2>
                            </div>
                      </div>    
                <span>Full Name</span>
                <br />
                <input classroom="input100" type="text" name="full_name" required />
                <br />
                <span>Phone No.</span>
                <br />
                <input classroom="input100" type="text" name="phone" required />
                <br />
                <span>Enter Email</span>
                <br />
                <input classroom="input100" type="text" name="email" required />
                <br />
              </div>

              <div className="margin-title-toe formWord">
                <span>Message</span>
                <br />
                <textarea name="message" required></textarea>
                <ReCAPTCHA
                    sitekey="6LdslJseAAAAACd3ZGUyU2uuAlxaPb4GEjic9PwW"
                    onChange={onChange}
                />
                <button classroom='button' id="button1"style={submitButtonStyle} disabled={recaptchaValue === null}>SUBMIT</button>
                <br/>
                <div classroom="row">
                  {result ? <Result/> : null}
                </div>
              </div>
            </form>  

    
  )
}

export default ContactComponents