import React, { Component } from "react";
import { MDBAnimation, MDBCarousel, MDBCarouselCaption, MDBCarouselInner, MDBCarouselItem, MDBView, MDBMask, MDBContainer, MDBRow } from
"mdbreact";
import {Link} from 'react-scroll'; 
import Certificates from "./certificates";

export class CarouselPage extends Component {
    render() {
        return (
            <MDBContainer fluid>
            <MDBRow >
            <MDBCarousel
            
            activeItem={1}
            length={1}
            showControls={false}
            showIndicators={false}
            id="Home_banner2"
            >
            <MDBCarouselInner className="">
                <MDBCarouselItem   itemId="1">
                <MDBView >
                    
                    <img className="d-block w-100 " src="images/bannerpic-7.jpg" alt="First slide" /> 
                    
                </MDBView>
                <MDBCarouselCaption>
                    <div  className="container-xl ">
                    
                        <div className="carousel-inner banner3 " >
                       
                        <div className="firstHeading  ">
                            <MDBAnimation className="slideInUp  " count={1} delay="0.5s" >
                               <h1 className="ttm-textcolor-darkgrey audit-header"><strong className="ttm-textcolor-blueskincolor">Free</strong> Salesforce Org Audit</h1>
                          </MDBAnimation>
                            <MDBAnimation className="slideInUp" count={1} delay="1s" >
                                <p className="audit-sub-header">Attain Ideal Platform Usage and Maximize ROI</p>
                            </MDBAnimation>
                            <MDBAnimation className="slideInUp" count={1} delay="1.5s" >
                           
                             
                               <img  className="certification-images" style={{ width: "45%", minHeight: "32px"  }} alt="certificates" src="images/certificates_pics.png"/> 
                           
                            </MDBAnimation>
                          
                            </div>
    
                            
                          </div>
                        </div>
                       
                     
            
                </MDBCarouselCaption>
                </MDBCarouselItem>


            </MDBCarouselInner>
            </MDBCarousel>
            </MDBRow>
            </MDBContainer>
        );
    }
}

export default CarouselPage;