import React, { Component } from 'react'
import { Menu1 } from './menu1';
import Mobilemenu1 from './Mobile_menu1';

export class Header1 extends Component {
    state = {
        show: false,
      }    
    toggle = () => this.setState((currentState) => ({show: !currentState.show}));
    render() {        
        return (
                <header id="masthead" className="header ttm-header-style-01">
                {/* ttm-topbar-wrapper */}
                
                {/* ttm-topbar-wrapper end */}
                {/* ttm-header-wrap */}
                <div className="ttm-header-wrap">
                <div id="navbar" className="ttm-stickable-header clearfix">
                    <div className="site-header-menu">
                        <div className="container-xl">
                            {/* site-branding */}
                            <div className="site-branding">
                            <a className="home-link" href={process.env.PUBLIC_URL + '/'} title="HTTPeak" rel="home">
                                <img id="logo-img" className="img-fluid" alt="logo" src="images/logo.png" />
                            </a>
                            </div>
                            {/* site-branding end*/}
                            {/*site-navigation */}
                            <div className="site-navigation">
                                <div className="ttm-right-header">
                                    
                                </div>
                                <Menu1 />
                                <div className="mobilemenu"><Mobilemenu1/></div>
                            </div>
                            {/*site-navigation end*/}
                        </div>
                    </div>
                </div>
                </div>
                {/* ttm-header-wrap end*/}
            </header>               
        )
    }
}

export default Header1;