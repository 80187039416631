import React, { Component } from "react";
import { MDBAnimation, MDBCarousel, MDBCarouselCaption, MDBCarouselInner, MDBCarouselItem, MDBView, MDBMask, MDBContainer, MDBRow } from
"mdbreact";
import {Link} from 'react-scroll'; 

export class CarouselPage extends Component {
    render() {
        return (
            <MDBContainer fluid>
            <MDBRow>
            <MDBCarousel
            activeItem={1}
            length={1}
            showControls={false}
            showIndicators={false}
            id="Home_banner"
            >
            <MDBCarouselInner>
                <MDBCarouselItem itemId="1">
                <MDBView>
                    <img className="d-block w-100" src="images/banner1.jpg" alt="First slide" />       
                </MDBView>
                <MDBCarouselCaption>
                    <div className="container-xl">
                        <div className="carousel-caption-inner banner1">
                       {/**  <MDBAnimation className="slideInUp" count={1} delay="4s" >
                            <img className="banner-logo" src="images/logo.png" alt="HTTPeak" /> 
                            </MDBAnimation>
                            */}
                            <div className="firstHeading">
                            <MDBAnimation className="slideInUp" count={1} delay="2s" >
                               <h3  className="color-blue">Salesforce Consulting Partner</h3>
                          </MDBAnimation>
                          </div>
                            <MDBAnimation className="slideInUp" count={1} delay="2s" >
                                <h3 className="ttm-textcolor-darkgrey">Business. Technology. <strong className="ttm-textcolor-skincolor logo-color">Connected.</strong></h3>
                            </MDBAnimation>
                            <MDBAnimation className="slideInUp" count={1} delay="2.5s" >
                                <p className="text"> Salesforce&nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;Integrations&nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;Marketing Automations&nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;Transformations</p>
                            </MDBAnimation>
                            <MDBAnimation className="slideInUp" count={1} delay="3s" >
                            <p>
                                <button  className="ttm-btn backhround-color ttm-btn-size-md ttm-bgcolor-skincolor ttm-textcolor-white"> <Link  to="getintouch" spy={true} smooth={true}>Discuss Your Project</Link></button>&nbsp; &nbsp;
                            </p>                            
                            </MDBAnimation>
                        </div>
                    </div>
                </MDBCarouselCaption>
                </MDBCarouselItem>
                 <MDBCarouselItem itemId="2">
                <MDBView>
                    <img className="d-block w-100" src="images/slide_1.jpg" alt="Second slide" />
                </MDBView>
                <MDBCarouselCaption>
                    <div className="container-xl">
                        <div className="carousel-caption-inner banner2">
                            <MDBAnimation className="slideInUp" count={1} delay="5s" >
                                <h2 className="ttm-textcolor-darkgrey">Real-Time Monitoring Your <strong className="ttm-textcolor-skincolor">Infrstracture</strong></h2>
                            </MDBAnimation>
                            <MDBAnimation className="slideInUp" count={1} delay="5.5s" >
                                <p className="text">Httpeak helps you transform your business into a digitalized network with salesforce.com.<br /> properly. Our method of application maintains the industry.</p>
                            </MDBAnimation>
                            <MDBAnimation className="slideInUp" count={1} delay="6s" >
                                <p>
                                    <button className="ttm-btn ttm-btn-size-md ttm-bgcolor-skincolor ttm-textcolor-white mr-3">read more</button>
                                    <button className="ttm-btn ttm-btn-size-md ttm-bgcolor-darkgrey ttm-textcolor-white">get altech</button>
                                </p>
                          </MDBAnimation>
                        </div>
                      </div>
                </MDBCarouselCaption>
                </MDBCarouselItem>
            </MDBCarouselInner>
            </MDBCarousel>
            </MDBRow>
            </MDBContainer>
        );
    }
}

export default CarouselPage;