import React, { Component } from 'react';
import Slider from 'react-slick';
import { MDBProgress } from 'mdbreact';
import { Header } from './layout/Header';
import { CarouselPage } from './banner/Home1_banner';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';
import Marquee from 'react-fast-marquee';
import ContactForm from './ContactForm';
import ContactComponents from './ContactComponents';
import Founder_detail from './Founder_detail';
import Our_expert from './pages/Our_expert';
import { Grid } from 'react-bootstrap';




const images = [
  'https://via.placeholder.com/600X750/444444.jpg',
  'https://via.placeholder.com/600X750/444444.jpg',
  'https://via.placeholder.com/600X750/444444.jpg',
  'https://via.placeholder.com/600X750/444444.jpg',
  'https://via.placeholder.com/600X750/444444.jpg'
];



export class Home extends Component {
  constructor(props) {
    super(props);

    this.state = {
      photoIndex: 0,
      isOpen: false,
    };
  }
  render() {
    const { photoIndex, isOpen } = this.state;
    var slick_slider = {
      dots: false,
      arrow: false,
      autoplay: true,
      infinite: true,
      speed: 500,
      slidesToShow: 4,
      slidesToScroll: 1,

      responsive: [

        {

          breakpoint: 1199,
          settings: {
            slidesToShow: 4,
            slidesToScroll: 4
          }
        },
        {

          breakpoint: 1024,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3
          }
        },
        {

          breakpoint: 680,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2
          }
        },
        {
          breakpoint: 575,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1
          }
        }]
    };

    return (
      <div id="header" className="site-main">
        <Header />
        {/* home banner */}
        <CarouselPage />{/* home banner end*/}
        <br />
        <div >
        {/* Lazy Admin Product Annoucement Section  */}
        <div >
          <div className="spacing-7 section-title with-desc">
            <div className="mb-4 title-header text-center">
              <h2 className="title lazy-admin-app">Try our <span>AppExchange App</span></h2>
            </div>
            {/* first section */}
            <div className=" clearfix">
              <div className="container-xl">
                <div className="row">
                  {/* section title */}
                  <div className="col-md-6">
                    <div className="section-title with-desc clearfix">
                      <img src="https://lazyadmin.httpeak.com/wp-content/uploads/2023/06/lazyadmin-logo-light.png" className="img-fluid lazy-admin-logo"/>
                      <h2 className="title lazy-admin-title">AI-Powered Quick Reporting Tool for Salesforce</h2>
                    </div>
                  </div>
                  {/* section title end */}
                  {/* section title */}
                  <div className="col-md-6">
                    <div className="section-title with-desc clearfix">
                      <div className="margin-body-top title-desc">
                        <h5 class="text-transform-none lazy-admin-desc mb-3 ">
                        <div >  
                        Our AppExchange product that saves you time by responding to "human language" and fetching quick reports from your data. 
                        </div>
                        <br/>
                        {/* Try for Free! */}
                        <div class="space_bottom">
                        <i class="ti ti-link space_right" ></i>
                        <a href="https://appexchange.salesforce.com/appxListingDetail?listingId=a0N3u00000RXGxBEAX" >Try for Free on AppExchange</a>
                        </div>
                        <div class="space_bottom">
                        <i class="ti ti-link space_right" ></i>
                        <a href="https://lazyadmin.httpeak.com/blog/meet-lazy-admin-your-ai-genie-for-salesforce-reporting-that-responds-to-human-language/">Read this blog to learn more</a>
                        </div>
                        <br/>

                        </h5>
                      </div>
                    </div>
                  </div>
                  {/* section title end */}
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* first Section ends */}






        <div className="section-title with-desc clearfix">
                  <div className=" text-center">
                    
                    <h2 className="title"> Some of our<span> clients</span></h2>
                  </div>
                  <div className="title-desc">
                  <img className='company-logos' src="./images/img20.jpg" />

                  </div>

                </div>
        

          {/* <Marquee pauseOnHover speed={100} >
              
                <div className="image_wrapper" >
                   <img className="img-fluid" src="./images/img1.png" alt="image" />
                </div>

                <div className="image_wrapper" >
                   <img className="img-fluid" src="./images/img2.png" alt="image" />
                </div>

                <div className="image_wrapper" >
                   <img className="img-fluid" src="./images/img3.png" alt="image" />
                </div>

                <div className="image_wrapper" >
                   <img className="img-fluid" src="./images/img4.png" alt="image" />
                </div>
                <div className="image_wrapper" >
                   <img className="img-fluid" src="./images/img5.png" alt="image" />
                </div>
                <div className="image_wrapper" >
                   <img className="img-fluid" src="./images/img6.png" alt="image" />
                </div>
                <div className="image_wrapper" >
                   <img className="img-fluid" src="./images/img7.png" alt="image" />
                </div>
                <div className="image_wrapper" >
                   <img className="img-fluid" src="./images/img8.png" alt="image" />
                </div>
                <div className="image_wrapper" >
                   <img className="img-fluid" src="./images/img9.png" alt="image" />
                </div>
              
            </Marquee> */}
        </div>
        <br />
        <br />


        {/* service-section end */}
        {/* history-section */}

        
        {/*  start */}

        <section id="whatwedo" className="ttm-row only-testimonial-section bg-img3 pb-0 res-991-pb-0 clearfix">
          <div className="container-xl">
            <div className="row">{/* row */}
              <div className="col-lg-12">
                {/* section title */}
                <div className=" section-title with-desc text-center clearfix">
                  <div className="margin-head-top title-header">
                    <h2 className="title"><span>What</span> we do</h2>
                  </div>
                </div>{/* section title end */}
              </div>
            </div>

          

              <div className="wrapper-grid2 ">

              <div className="Box-Shadow">
      <div className="ttm-history-box-border" />{/* ttm-history-box-border  */}
    <div className="ttm-history-box-det  ttm-bgcolor-white">
      <div className="ttm-historybox-title text-center"><h5 className='blue-color'>ADVISORY</h5></div>{/* historybox-title  */}
      <div className="ttm-historybox-description">{/* description  */}
        We understand that every business is different. That is why we explore and find out rather than assuming your business needs.
        We serve as your tour guides to help you define a shared vision for digital transformation through innovative thinking.
    <br/>
    <br/>
    <br/>
    </div>
  </div>
</div>

<div className="Box-Shadow">
    <div className="ttm-history-box-border" />{/* ttm-history-box-border  */}
    <div className="ttm-history-box-det ttm-bgcolor-white">
      <div className="ttm-historybox-title text-center"><h5 className='blue-color'>MANAGED SERVICES</h5></div>{/* historybox-title  */}
      <div className="ttm-historybox-description">{/* description  */}
        With our unique flex-utilisation model, we are able to work around your business needs. That can be clearing up your backlog or consulting on feature enhancements.
        With Managed Services you will be more efficient with the utilisation of your Salesforce Org.
        <br/>
        <br/>
          </div>
  </div>
 </div>
 <div className="Box-Shadow">
    <div className="ttm-history-box-border" />{/* ttm-history-box-border  */}
    <div className="ttm-history-box-det ttm-bgcolor-white">
      <div className="ttm-historybox-title text-center"><h5 className='blue-color'>OUR INDUSTRIES</h5></div>{/* historybox-title  */}
      <div className="ttm-historybox-description">{/* description  */}
        Besides Salesforce cross-cloud experience, our team of experts have industry specific expertise as well, such as <p className="Bold-Text-Style"> Education, Insurance, Travel, Government, Airlines, High Tech, Utility, Retail and more.</p>
        <br/>
        <ln />
      </div>
  </div>
</div>
            </div>

<br/>
            <div className="wrapper-grid2 ">

            <div className="Box-Shadow">
                
                  <div className="ttm-history-box-border" />{/* ttm-history-box-border  */}
                  <div className="ttm-history-box-det ttm-bgcolor-white">
                    <div className="ttm-historybox-title text-center"><h5 className='blue-color'>SYSTEMS INTEGRATION</h5></div>{/* historybox-title  */}
                    <div className="ttm-historybox-description">{/* description  */}
                      In an organisation, you have a variety of independent data sources. Having your data connected to all of your systems (CRM and non-CRM) allows all of your staff to view it and to unlock the maximum value possible from your existing systems.
                      We specialize in all sorts of Integrations, whether Point-To-Point, ESB or Middleware based, Platform Events based or Message Queue based.
                      <br />
                      <br />
                      <br />
                      <br />
                      <br />
                      <br />
                      <br />
                    </div>
                
                </div>
              </div>

              <div className="Box-Shadow">
                  <div className="ttm-history-box-border" />{/* ttm-history-box-border  */}
                  <div className="ttm-history-box-det ttm-bgcolor-white">
                    <div className="ttm-historybox-title text-center "><h5 className='blue-color'>CROSS-CLOUD SALESFORCE IMPLEMENTATION</h5></div>{/* historybox-title  */}
                    <div className="ttm-historybox-description">{/* description  */}
                      Our team of experts offers cross-cloud implementation services on Salesforce. Once we understand your organization's ecosystem, we start with something small, demonstrate that it works and then build on it. <br />
                      <br />
                      Our services mainly include:<br />
                     <ul className='Navy-Blue'>
                      <li>• Sales Cloud</li>
                      <li>• Service Cloud</li>
                      <li>• Marketing Cloud and Pardot</li>
                      <li>• Community Cloud </li>
                      <li>• Vlocity and other CPQ Solutions </li>
                      <li>• Einstein Analytics </li>
                      <li>• Field Service Lightning</li>
                     </ul>                      
                    </div>
                </div>
              </div>

              
              <div className="Box-Shadow">
                   <div className="ttm-history-box-border" />{/* ttm-history-box-border  */}
                  <div className="ttm-history-box-det ttm-bgcolor-white ">
                    <div className="ttm-historybox-title text-center"><h5 className='blue-color'>BESPOKE SOLUTIONS</h5></div>{/* historybox-title  */}
                    <div className="ttm-historybox-description">{/* description  */}

                      Every organization has other applications running as well other than Salesforce in their IT landscape. Considering this need, we also offer consulting on Bespoke solutions, such as Google Cloud Platform, Mobile Apps, Content Management Systems (such Wordpress), AWS, Data Migations and Custom Applications.
                      This makes us a one-stop solution for our customers, for Salesforce and Non-Salesforce services.
                      <br />
                      <br />
                      <br />
                      <br />
                      <br />
                      <br />
                    </div>
                  </div>
              </div>



            </div>  

              

             </div>
            
          
        </section>

        {/* end */}
        




        {/* history-section end*/}
        {/* zero-padding-section */}
        {/* zero-padding-section end */}
        {/* team-section */}
        {/* aboutus-section */}
        <section id="whoweare" className="ttm-row aboutus-section clearfix">
          <div className="container-xl">
            <div className="row">
              <div className="col-lg-6">
                {/* section title */}
                <div className="section-title with-desc clearfix">
                  <div className="title-header margin-head-top">
                    
                    <h2 className="title"><span>Who</span> we are?</h2>
                  </div>
                  <div className="margin-body-top title-desc">
                    <p>HTTPeak Pty Ltd. offers Salesforce and bespoke consulting services at a competitive price. Our journey started in 2016 from Melbourne, Australia.</p>
                    <p>Instead of just hard-selling everything, we listen to your requirements and budget constraints carefully and come up with the most appropriate solution that fits the bill.</p>
                    <p>Our leadership is highly technical, and our experts have specific industry experience with Salesforce implementations. Besides considering all the aspects such as Cost, Scalability, Business needs, solid architecture; we also perform peer-review on our work to maintain the quality.</p>
                    <p>That's  the reason why we have loyal customers that prefer to stay with us in a long term relationship.</p>
                  </div>

                </div>
                {/* section title end */}
                {/* row */}
                <div className="row no-gutters mt-4">
                  {/* <div className="col-md-6 col-lg-6 col-sm-6">
                        <ul className="ttm-list ttm-list-style-icon">
                          <li><i className="far fa-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content">Web And Mobile Application </span></li>
                          <li><i className="far fa-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content">New Domain Registration</span></li>
                          <li><i className="far fa-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content">Data Text Synchronization</span></li>
                        </ul>
                      </div>
                      <div className="col-md-6 col-lg-6 col-sm-6">
                        <ul className="ttm-list ttm-list-style-icon">
                          <li><i className="far fa-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content">Improving Our Website Design</span></li>
                          <li><i className="far fa-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content">Web &amp; Email Hosting Services </span></li>
                          <li><i className="far fa-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content">Artificial Intelligence Web App</span></li>
                        </ul>
                      </div>*/}
                </div>{/* row END*/}
                {/* separator */}
                <div className="separator">
                  <div className="sep-line mt-4 mb-4" />
                </div>
                {/* separator */}

              </div>
              <div className="col-lg-5 res-991-mt-40 res-991-text-center">
                <div className="size-img position-relative res-991-mt-30">
                  {/* ttm_single_image-wrapper */}
                  <div className="">
                    <img className=" img-fluid" src="images/about_section2.jpg" title="single-img-one" alt="single-img-one" />
                  </div>{/* ttm_single_image-wrapper end */}
                  {/*featured-icon-box
                      <div className="about-overlay-01">
                        <p className="mb-0">HTTPeak Pty Ltd.</p>
                      </div>
                      {/* featured-icon-box end*/}
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* aboutus-section end */}





        {/* Testimonial start */}

        <section className="ttm-row only-testimonial-section bg-img11 pb-0 res-1991-pb-0 clearfix">
          <div className="container-xl">
            <div className="row">{/* row */}
              <div className="col-lg-12">
                {/* section title */}
                <div className="section-title with-desc text-center clearfix">
                  <div className="title-header margin-head-top">

                    <h2 className="title">What our clients say <span>about us</span></h2>
                  </div>
                </div>{/* section title end */}
              </div>
            </div>

            
              <div className="wrapper-grid">


                <div className="testimonials text-center">
                  <div className="testimonial-content">{/* testimonials-content */}
                    <div className="testimonial-avatar">
                      <div className="testimonial-img1">{/* testimonials-img */}
                        <img className="img-size img-center" alt="testimonial-img" src="images/userlogo.png" />
                      </div>
                    </div>
                    <blockquote><p className="quotation-mark">“</p><br/>The httpeak team supports us with consulting and integration around the Salesforce Marketing Cloud. They understand the requirements very quickly and implement everything as agreed. It is a pleasure to work with their team and we can highly recommend working with them.​</blockquote>
                    <div className="ttm-ratting-star">
                      <i className="fa fa-star" />
                      <i className="fa fa-star" />
                      <i className="fa fa-star" />
                      <i className="fa fa-star" />
                      <i className="fa fa-star" />
                    </div>
                    <div className="testimonial-caption">{/* testimonials-caption */}
                      <h6>Gregor Nilsson</h6>
                      <label>Partner, Managing Director Switzerland</label>
                    </div>
                  </div>
                </div>


                <div className="testimonials text-center">
                  <div className="testimonial-content">{/* testimonials-content */}
                    <div className="testimonial-avatar">
                      <div className="testimonial-img1">{/* testimonials-img */}
                        <img className="img-size img-center" alt="testimonial-img" src="images/userlogo2.png" />
                      </div>
                    </div>
                    <blockquote><p className="quotation-mark">“</p><br/> We love the approach HTTPeak take to support our company.​ They provide flexibility, personalised service with a structure approach to our needs.

                      It’s not just about solving the immediate problem but looking at it from a bigger perspective with the idea of how it will help 2-3 steps later. We are looking forward to growing our business with the technical support of HTTPeak solutions.​</blockquote>
                    <div className="ttm-ratting-star">
                      <i className="fa fa-star" />
                      <i className="fa fa-star" />
                      <i className="fa fa-star" />
                      <i className="fa fa-star" />
                      <i className="fa fa-star" />
                    </div>
                    <div className="testimonial-caption">{/* testimonials-caption */}
                      <h6>Onray Fernando</h6>
                      <label>National Eye Care</label>
                    </div>
                  </div>
                </div>
                </div>  


              <div>
                <div className="testimonials text-center">
                  <div className="testimonial-content">{/* testimonials-content */}
                    <div className="testimonial-avatar">
                      <div className="testimonial-img1">{/* testimonials-img */}
                        <img className="img-size img-center" alt="testimonial-img" src="images/userlogo3.png" />
                      </div>
                    </div>
                    <blockquote><p className="quotation-mark">“</p><br/> While our latest product offering is in its infancy, our challenge statement to the team at HTTPeak was quite complex in both business and functional requirements.
                      The technical and delivery solution provided to solve our current and future Salesforce architecture requirements is of top quality.<br />

                      The technical and delivery solution provided to solve our current and future Salesforce architecture requirements is of top quality. I have particularly been impressed with the speed and autonomy of the change requirements beyond the introductory analysis meetings. I look forward to continuing our successful relationship together as our business grows.​</blockquote>
                    <div className="ttm-ratting-star">
                      <i className="fa fa-star" />
                      <i className="fa fa-star" />
                      <i className="fa fa-star" />
                      <i className="fa fa-star" />
                      <i className="fa fa-star" />
                    </div>
                    <div className="testimonial-caption">{/* testimonials-caption */}
                      <h6>Nadir</h6>
                      <label>CEO at PSLoop</label>
                    </div>
                  </div>
                </div>
              </div>
             </div>
            
          
        </section>

        {/* Testimonial end */}

        {/*Founder Detail Start*/}

        <Founder_detail />


        {/*Founder Detail End*/}

        {/* Testimonial end */}

        {/* Contact Form */}
        <div class="contactme" id="contact">
          <div class="contactOverlay">
            <div class="contai ner">
              <div class="form">
                <ContactComponents />
              </div>
            </div>
          </div>
        </div>

        {/* <section id="getintouch" className="ttm-row team-section res-991-pt-0 clearfix">
              <div className="container-xl">
w                <div className="row align-items-center mb-5">
                  <div className="col-lg-5 res-991-text-center">
                    <div className="section-title style2 mb-0 clearfix">
                      <div className="title-header mb-0">
                        <h5>About us</h5>
                        <h2 className="title">Meet our <span>Leadership</span></h2>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-5 res-991-text-center">
                    <div className="title-desc">The positive distinction within the World Inspiring of Success and Connections of People and Organizations.</div>
                  </div>
                 
                </div>

              </div>
            </section> */}
        {/**Remove Slider  code of line 283*/}

        {/* team-section end*/}
        {/* testimonial-section end */}
        {/* process-section */}
        {/* process-section end */}
        {/* blog-section */}
        {/* blog-section end */}
      </div>
    )
  }
}


export default Home;
